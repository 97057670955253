module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.0.0_gatsby@5.0.1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.0.0_a4qml6ticoboctv7rf7cgyl4ny/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robert Smieja","short_name":"Robert Smieja","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/android-chrome-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e3de54deb5bea83bd830f2035353c870"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.0.1_yalvw3r2waubxycyb7k7qsruca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
